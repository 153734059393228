import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Spin,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  getAllCompaniesData,
  getOneInItem,
  getUserById,
  updateUser,
} from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import CompanyDropdown from "../../../components/Dropdowns/CompanyDropdown";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { isMobile } from "react-device-detect";

const { Text } = Typography;
const EditInputItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [itemData, setItemData] = useState({});
  const [showCompanyDropDown, setShowCompanyDropDown] = useState(false);
  const [otherText, setOtherText] = useState(false);
  const [searchParams] = useSearchParams();
  const itemId = searchParams.get("id");

  const navigate = useNavigate();

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await updateUser(
        {
          ...values,
          companyIds: [selectedCompany?._id],
        },
        itemId
      );
      message.success("Company admin updated!");
      navigate("/admin/company-admins");
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      message.error(error.response.data.error);
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getInItemData = async () => {
    try {
      const { data } = await getOneInItem(itemId);
      setItemData(data?.inItem);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getInItemData();
  }, []);

  const [form] = Form.useForm();
  useEffect(() => {
    if (itemData) {
      console.log({ itemData });
      form.setFieldsValue({
        serialNumber: itemData?.serialNumber,
        condition: itemData?.condition,
        driver: itemData?.driver,
        item: itemData?.item,
        marka: itemData?.marka,
        name: itemData?.name,
        quantity: itemData?.quantity,
        ringNumber: itemData?.ringNumber,
        roomNumber: itemData?.roomNumber,
        totalQuantity: itemData?.totalQuantity,
        vehicalNumber: itemData?.vehicalNumber,
      });
    }
  }, [itemData, form]);

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <>
          <div className="flex justify-between items-center px-3 py-4 w-full">
            <Text className="text-2xl font-semibold font-[#1f2232]">
              Edit Input Item
            </Text>
          </div>
          <div className="p-3">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              className="w-full"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-wrap">
                <Form.Item
                  label="Serial Number"
                  name="serialNumber"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input serial number!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input name!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Item"
                  name="item"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input item!",
                    },
                  ]}
                >
                  {otherText ? (
                    <Input size="large" />
                  ) : (
                    <Select
                      placeholder="Select item..."
                      size="large"
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                      onChange={(val) => val === "Other" && setOtherText(true)}
                      options={[
                        {
                          value: "Patroji",
                          label: "Patroji",
                        },
                        {
                          value: "Apple",
                          label: "Apple",
                        },
                        {
                          value: "Kajhor",
                          label: "Kajhor",
                        },
                        {
                          value: "Kenu",
                          label: "Kenu",
                        },
                        {
                          value: "Anar",
                          label: "Anar",
                        },
                        {
                          value: "Alocha",
                          label: "Alocha",
                        },
                        {
                          value: "Nashpati",
                          label: "Nashpati",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label="Quantity"
                  name="quantity"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input quantity!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Condition"
                  name="condition"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input condition!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Driver"
                  name="driver"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input driver!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Marka"
                  name="marka"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input marka!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Room Number"
                  name="roomNumber"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input room number!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Ring Number"
                  name="ringNumber"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input ring number!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Vehical Number"
                  name="vehicalNumber"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input vehical number!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item className="flex justify-end w-full">
                  <Button
                    className="mr-2"
                    onClick={() => setIsModalOpen(true)}
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button size="large" htmlType="submit">
                    {isLoading ? (
                      <div className="flex items-center">
                        <Spin
                          className="mr-2"
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 18,
                              }}
                              spin
                            />
                          }
                        />
                        Save
                      </div>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <DeleteModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              deleteAction={() => navigate("/admin/input-items")}
              title="Discard changes"
              actionText="Yes"
              text="Are you sure you want to discard?"
            />
          </div>
        </>
      </Card>
    </div>
  );
};

export default EditInputItems;
