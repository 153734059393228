import axios from "axios";

import {
  LOGIN,
  SIGNUP,
  COMPANY,
  GET_ALL,
  USER,
  CREATE_COMPANY_ADMIN,
  CREATE_SUPER_ADMIN,
  CREATE,
  GET_BY_ID,
  UPDATE,
  GET_COMPANY_DETAILS_BY_ID,
  BLOCK,
  UNBLOCK,
  DELETE,
  ADD_INVITES,
  TAG,
  CHALLENGE,
  OPTIONS,
  ASSESSMENT,
  ASSIGN_ASSESSMENTS,
  GET_ASSESSMENT,
  VALIDATE_ASSESSMENTS_TOKEN,
  ASSESSMENTS_MAPPING,
  RESULT,
  CHANGE_PASSWORD,
  FEEDBACK,
  CLOSE,
  SETTINGS,
  EMAILS,
  CREATE_USER_IF_NOT_EXISTS,
  INITEM,
  ALL_INITEM,
  OUTITEM,
  ALL_OUTITEM,
  TRANSACTION,
  INITEM_QUANTITY,
  OUTITEM_QUANTITY,
  RENT,
  TOTAL,
  PAID,
  REMAINING,
} from "../../constants";
import {
  COLUMN_DATA,
  DISCOUNTS,
  OUTITEM_BY_S_NO,
  TRANSACTION_BY_S_NO,
} from "../../constants/endPoints";

const {
  REACT_APP_AXIOS_RETRY,
  REACT_APP_API_PREFIX,
  // REACT_APP_CONTENT_TYPE,
  // REACT_APP_APPLICATION_X_WWW_FORM_URLENCODED,
} = process.env;

// Constants
const AXIOS_RETRY = REACT_APP_AXIOS_RETRY;
const API_PREFIX = REACT_APP_API_PREFIX;
// const CONTENT_TYPE = REACT_APP_CONTENT_TYPE;
// const APPLICATION_X_WWW_FORM_URLENCODED =
//   REACT_APP_APPLICATION_X_WWW_FORM_URLENCODED;
const axiosInstance = axios.create({
  baseURL: API_PREFIX,
  [AXIOS_RETRY]: {
    retries: 3,
  },
  errorHandling: {
    global: true,
  },
});

// Add request interceptor to set Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = JSON.parse(localStorage.getItem("accessToken"));
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const createInItem = (data) => {
  return axiosInstance.post(`${API_PREFIX}${INITEM}/${CREATE}`, data);
};

export const getAllInItems = (page = 1, limit = 10, search = "") => {
  return axiosInstance.get(
    `${API_PREFIX}${ALL_INITEM}?page=${page}&limit=${limit}&search=${search}`
  );
};

export const getOneInItem = (id) => {
  return axiosInstance.get(`${API_PREFIX}${INITEM}/${id}`);
};

export const getGraphData = () => {
  return axiosInstance.get(`${API_PREFIX}${COLUMN_DATA}`);
};

export const deleteInItemById = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${INITEM}/${DELETE}/${id}`);
};

export const deleteOutItemById = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${OUTITEM}/${DELETE}/${id}`);
};

export const createOutItem = (data) => {
  return axiosInstance.post(`${API_PREFIX}${OUTITEM}/${CREATE}`, data);
};

export const createTransaction = (id, data) => {
  return axiosInstance.post(`${API_PREFIX}${TRANSACTION}/${id}`, data);
};

export const getTransaction = (id) => {
  return axiosInstance.get(`${API_PREFIX}${TRANSACTION}/${id}`);
};

export const getTransactionBySno = (id) => {
  return axiosInstance.get(`${API_PREFIX}${TRANSACTION_BY_S_NO}/${id}`);
};

export const getOutItemsBySno = (sNo) => {
  return axiosInstance.get(`${API_PREFIX}${OUTITEM_BY_S_NO}/${sNo}`);
};

export const getAllOutItems = (page = 1, limit = 10, search = "") => {
  return axiosInstance.get(
    `${API_PREFIX}${ALL_OUTITEM}?page=${page}&limit=${limit}&search=${search}`
  );
};

export const getAllInItemsCount = () => {
  return axiosInstance.get(`${API_PREFIX}${INITEM_QUANTITY}`);
};

export const getAllOutItemsCount = () => {
  return axiosInstance.get(`${API_PREFIX}${OUTITEM_QUANTITY}`);
};

export const getAllRents = () => {
  return axiosInstance.get(`${API_PREFIX}${RENT}/${TOTAL}`);
};

export const getAllPaidRents = () => {
  return axiosInstance.get(`${API_PREFIX}${RENT}/${PAID}`);
};

export const getAllRemainingRents = () => {
  return axiosInstance.get(`${API_PREFIX}${RENT}/${REMAINING}`);
};

export const getAllDiscounts = () => {
  return axiosInstance.get(`${API_PREFIX}${DISCOUNTS}`);
};

// TODO: remove these

export const createUserIfNotExist = (data) => {
  return axios.post(`${API_PREFIX}${CREATE_USER_IF_NOT_EXISTS}`, data);
};

export const login = ({ email, password }) => {
  return axiosInstance.post(`${API_PREFIX}${LOGIN}`, { email, password });
};

export const getUserProfile = (id) => {
  return axios.get(`${API_PREFIX}user/getByCognitoId/${id}`);
};
export const setUserData = (data) => {
  return axios.post(`${API_PREFIX}user/addUser`, data);
};

export const signup = (data) => {
  return axiosInstance.post(`${API_PREFIX}${SIGNUP}`, data);
};

export const getAllCompaniesData = (queryString) => {
  return axiosInstance.get(`${API_PREFIX}${COMPANY}/${GET_ALL}?${queryString}`);
};

export const getChallenges = (limit = 100, page = 1) => {
  return axiosInstance.get(
    `${API_PREFIX}${CHALLENGE}?limit=${limit}&page=${page}`
  );
};

export const getChallengesByType = (
  type,
  tags,
  page = 1,
  limit = 10,
  sortBy,
  order
) => {
  return axiosInstance.get(
    `${API_PREFIX}${CHALLENGE}?typeOfChallenge=${type}&tags=${tags}&page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`
  );
};

export const searchChallenges = (key) => {
  return axiosInstance.get(`${API_PREFIX}${CHALLENGE}?q=${key}`);
};

export const getUserById = (id) => {
  return axiosInstance.get(`${API_PREFIX}${USER}/${GET_BY_ID}/${id}`);
};

export const getAssessmentById = (id) => {
  return axiosInstance.get(`${API_PREFIX}${ASSESSMENT}/${id}`);
};

export const getAssessmentResults = (userId, assessmentId, companyId) => {
  return axiosInstance.get(
    `${API_PREFIX}${RESULT}/${userId}/${assessmentId}/${companyId}`
  );
};

export const deleteUserById = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${USER}/${id}`);
};

export const deleteAssessmentById = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${ASSESSMENT}/${id}`);
};

export const deleteTagById = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${TAG}/${id}`);
};

export const getCompanyById = (id) => {
  return axiosInstance.get(
    `${API_PREFIX}${COMPANY}/${GET_COMPANY_DETAILS_BY_ID}/${id}`
  );
};

export const getChallengeById = (id) => {
  return axiosInstance.get(`${API_PREFIX}${CHALLENGE}/${id}`);
};

export const getOptions = (type) => {
  return axiosInstance.get(`${API_PREFIX}${OPTIONS}?typeOfOptions=${type}`);
};

export const getAllCompanyAdmins = (queryString) => {
  return axiosInstance.get(`${API_PREFIX}${USER}/${GET_ALL}?${queryString}`);
};

export const getAllSuperAdmins = (queryString) => {
  return axiosInstance.get(`${API_PREFIX}${USER}/${GET_ALL}?${queryString}`);
};

export const getAllAssessments = (page = 1, limit = 10, sortBy, order) => {
  return axiosInstance.get(
    `${API_PREFIX}${ASSESSMENT}?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`
  );
};

export const getAllAssessmentsByUserId = (id) => {
  return axiosInstance.get(`${API_PREFIX}assessments-mapping?userId=${id}`);
};

export const getAllAssessmentsOfCompany = (
  id,
  page = 1,
  limit = 10,
  sortBy,
  order
) => {
  return axiosInstance.get(
    `${API_PREFIX}${COMPANY}/${GET_ASSESSMENT}/${id}?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`
  );
};

export const validateToken = (token) => {
  return axiosInstance.get(
    `${API_PREFIX}${COMPANY}/${VALIDATE_ASSESSMENTS_TOKEN}?token=${token}`
  );
};

export const updateSettings = (data) => {
  return axiosInstance.post(`${API_PREFIX}${SETTINGS}`, data);
};

export const updateTemplates = (data) => {
  return axiosInstance.post(`${API_PREFIX}${EMAILS}`, data);
};

export const createCompanyAdmin = (data) => {
  return axiosInstance.post(
    `${API_PREFIX}${USER}/${CREATE_COMPANY_ADMIN}`,
    data
  );
};

export const startMyAssessment = (data) => {
  return axiosInstance.post(`${API_PREFIX}${RESULT}`, data);
};

export const endMyAssessment = (userId, assessmentId, companyId) => {
  return axiosInstance.post(
    `${API_PREFIX}${RESULT}/${CLOSE}/${userId}/${assessmentId}/${companyId}`
  );
};

export const createAssessmentsMapping = (data) => {
  return axiosInstance.post(`${API_PREFIX}${ASSESSMENTS_MAPPING}`, data);
};

export const createAssessment = (data) => {
  return axiosInstance.post(`${API_PREFIX}${ASSESSMENT}`, data);
};

export const associatCompaniesAndAssessments = (data) => {
  return axiosInstance.post(
    `${API_PREFIX}${COMPANY}/${ASSIGN_ASSESSMENTS}`,
    data
  );
};

export const updateUser = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${USER}/${UPDATE}/${id}`, data);
};

export const updateFeedback = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${FEEDBACK}/${id}`, data);
};

export const updatePassword = (data) => {
  return axiosInstance.patch(`${API_PREFIX}${USER}/${CHANGE_PASSWORD}`, data);
};

export const updateAssessment = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${ASSESSMENT}/${id}`, data);
};

export const updateChallenge = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${CHALLENGE}/${id}`, data);
};

export const updateTag = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${TAG}/${id}`, data);
};

export const blockUser = (id) => {
  return axiosInstance.post(`${API_PREFIX}${USER}/${BLOCK}/${id}`, {});
};

export const unBlockUser = (id) => {
  return axiosInstance.post(`${API_PREFIX}${USER}/${UNBLOCK}/${id}`, {});
};

export const blockCompany = (id) => {
  return axiosInstance.post(`${API_PREFIX}${COMPANY}/${BLOCK}/${id}`, {});
};

export const unBlockCompany = (id) => {
  return axiosInstance.post(`${API_PREFIX}${COMPANY}/${UNBLOCK}/${id}`, {});
};

export const updateCompany = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${COMPANY}/${UPDATE}/${id}`, data);
};

export const addCompanyInvites = (data, id) => {
  return axiosInstance.post(
    `${API_PREFIX}${COMPANY}/${ADD_INVITES}/${id}`,
    data
  );
};

export const createTag = (data) => {
  return axiosInstance.post(`${API_PREFIX}${TAG}`, data);
};

export const createChallenge = (data) => {
  return axiosInstance.post(`${API_PREFIX}${CHALLENGE}`, data);
};

export const getTags = (page = 1, limit = 10, sortBy, order) => {
  return axiosInstance.get(
    `${API_PREFIX}${TAG}?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`
  );
};

export const getSettings = () => {
  return axiosInstance.get(`${API_PREFIX}${SETTINGS}`);
};

export const getTemplates = () => {
  return axiosInstance.get(`${API_PREFIX}${EMAILS}`);
};

export const getFeedbacks = (page = 1, limit = 10, sortBy, order) => {
  return axiosInstance.get(
    `${API_PREFIX}${FEEDBACK}?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`
  );
};

export const createSuperAdmin = (data) => {
  return axiosInstance.post(`${API_PREFIX}${USER}/${CREATE_SUPER_ADMIN}`, data);
};

export const createCompany = (data) => {
  return axiosInstance.post(`${API_PREFIX}${COMPANY}/${CREATE}`, data);
};

export const deleteCompany = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${COMPANY}/${DELETE}/${id}`, {});
};

export const deleteChallenge = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${CHALLENGE}/${id}`, {});
};
