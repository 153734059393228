import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import {
  getAllCompaniesData,
  getUserById,
  updateUser,
} from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import CompanyDropdown from "../../../components/Dropdowns/CompanyDropdown";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { isMobile } from "react-device-detect";

const { Text } = Typography;
const EditOutputItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [userData, setUserData] = useState({});
  const [showCompanyDropDown, setShowCompanyDropDown] = useState(false);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id");

  const navigate = useNavigate();

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await updateUser(
        {
          ...values,
          companyIds: [selectedCompany?._id],
        },
        userId
      );
      message.success("Company admin updated!");
      navigate("/admin/company-admins");
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      message.error(error.response.data.error);
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getUserData = async () => {
    try {
      const { data } = await getUserById(userId);
      setUserData(data);
    } catch (error) {
      console.log({ error });
    }
  };

  const getAllCompanies = async () => {
    try {
      const { data } = await getAllCompaniesData();
      setAllCompanies(data?.companies);
      setSearchedCompanies(data?.companies);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllCompanies();
    getUserData();
  }, []);

  const handleCompanySearch = (e) => {
    const val = e.target.value;
    const filteredArr = allCompanies.filter((item) => item?.name.includes(val));
    setSearchedCompanies(filteredArr);
  };

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
    });
    if (userData?.companyIds?.length) {
      setSelectedCompany({ ...userData?.companyIds[0] });
    }
  }, [userData, form]);

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <>
          <div className="flex justify-between items-center px-3 py-4 w-full">
            <Text className="text-2xl font-semibold font-[#1f2232]">
              Edit Output Item
            </Text>
          </div>
          <div className="p-3">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              className="w-full"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-wrap">
                <Form.Item
                  label="Exit Number"
                  name="exitNumber"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input exit number!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Payment"
                  name="payment"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input payment!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Time spent"
                  name="time"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input time!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Rent"
                  name="rent"
                  className={isMobile ? "w-full px-2" : "w-1/2 px-2"}
                  rules={[
                    {
                      required: true,
                      message: "Please input rent!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item className="flex justify-end w-full">
                  <Button
                    className="mr-2"
                    onClick={() => setIsModalOpen(true)}
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button size="large" htmlType="submit">
                    {isLoading ? (
                      <div className="flex items-center">
                        <Spin
                          className="mr-2"
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 18,
                              }}
                              spin
                            />
                          }
                        />
                        Save
                      </div>
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <DeleteModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              deleteAction={() => navigate("/admin/output-items")}
              title="Discard changes"
              actionText="Yes"
              text="Are you sure you want to discard?"
            />
          </div>
        </>
      </Card>
    </div>
  );
};

export default EditOutputItems;
