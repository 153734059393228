import { Typography } from "antd";
import React from "react";
import Stars from "./../../assets/background/Stars.png";
import TopPart from "./../../assets/background/TopPart.png";
import Background from "./../../assets/background/Background.png";
import { useLocation } from "react-router-dom";
import "./AuthBackground.css";

export default function AuthBackground({ children }) {
  const location = useLocation();
  return (
    <div
      style={{
        background: `url('${Background}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundAttachment: "fixed", // Make background fixed
      }}
      className={`${
        location.pathname === "/signup" ? "py-20" : "py-20"
      } relative text-white w-full object-cover flex justify-center items-center`}
    >
      <div
        className="fixed bg-red-300 -mt-56"
        style={{
          background:
            location.pathname === "/signup"
              ? `url('${Stars}')`
              : `url('${TopPart}')`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: location.pathname === "/signup" ? "600px" : "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundAttachment: "fixed", // Make background fixed
        }}
      ></div>
      <div style={{ width: "400px" }} className="flex flex-col items-center">
        {/* <img className="w-56" src="../assets/logo-dark.png" alt="Cyber hire" /> */}
        {children}
      </div>
    </div>
  );
}
