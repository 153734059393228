import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// import SSO from "../pages/SSO";
import Login from "../pages/Auth/Login";
// import ResetPassword from "../pages/Auth/ResetPassword";
// import Signup from "../pages/Auth/Signup";
// import NewPassword from "../pages/Auth/NewPassword";
// import OTP from "../pages/Auth/OTP";
import Dashboard from "../pages/Admin/Dashboard";
import AdminLayout from "../layout/AdminLayout";
import CreateInputItems from "../pages/Admin/Items/CreateInputItems";
import EditInputItems from "../pages/Admin/Items/EditInputItems";
import InputItems from "../pages/Admin/Items/InputItems";
import OutputItems from "../pages/Admin/OutputItems/OutputItems";
import CreateOutputItems from "../pages/Admin/OutputItems/CreateOutputItems";
import EditOutputItems from "../pages/Admin/OutputItems/EditOutputItems";

// const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

// const PublicRoute = ({ component: Component, ...rest }) => {
//   // State to track the authentication status and loading state
//   const [authenticated, setAuthenticated] = useState(true);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     // Function to check authentication status
//     const token = localStorage.getItem("token");
//     const checkAuthentication = async () => {
//       // try {
//       //   if (token) {
//       //     const { data, status } = await getAccount(token);
//       //     if (status === 200) {
//       //       setAuthenticated(false);
//       //     } else {
//       //       setAuthenticated(true);
//       //     }
//       //   } else {
//       //     setAuthenticated(true);
//       //   }
//       // } catch (error) {
//       //   console.log({ error });
//       //   setAuthenticated(true);
//       // } finally {
//       //   // Set loading to true after authentication check is done
//       //   setLoading(false);
//       // }
//     };

//     // checkAuthentication();
//   }, []); // Empty dependency array to run the effect only once

//   if (loading) {
//     // Show loading indicator or some placeholder while checking authentication
//     return (
//       <div
//         style={{ height: "100vh" }}
//         className="flex justify-center items-center w-full"
//       >
//         <Spin indicator={antIcon} />
//       </div>
//     );
//   }

//   return authenticated ? <Component {...rest} /> : <Navigate to="/" replace />;
// };

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/auth" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
