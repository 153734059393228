import { Divider, Typography } from "antd";
import DashboardCard from "../../components/DashboardCard";
import ColumnGraph from "../../components/Graphs/ColumnGraph";
import {
  getAllDiscounts,
  getAllInItemsCount,
  getAllOutItemsCount,
  getAllPaidRents,
  getAllRemainingRents,
  getAllRents,
  getGraphData,
} from "./../../services/api";
import { useState, useEffect } from "react";
const Dashboard = () => {
  const [inItemCount, setInItemCount] = useState(0);
  const [outItemCount, setOutItemCount] = useState(0);
  const [totalRent, setTotalRent] = useState(0);
  const [paidRent, setPaidRent] = useState(0);
  const [remainingRent, setRemainingRent] = useState(0);
  const [discounts, setDiscounts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([
    { name: "Apple", count: 180, time: "all time" },
    { name: "Kinow", count: 180, time: "all time" },
    { name: "Patroji", count: 177, time: "all time" },
    { name: "Anar", count: 45, time: "all time" },
    { name: "Alocha", count: 136, time: "all time" },
    { name: "Nashpati", count: 288, time: "all time" },
  ]);

  const getInItemsCount = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllInItemsCount();
      setInItemCount(data?.totalQuantity);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getOutItemsCount = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllOutItemsCount();
      setOutItemCount(data?.totalQuantity);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalRent = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllRents();
      setTotalRent(data?.totalCost);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getPaidRent = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllPaidRents();
      setPaidRent(data?.paidAmount);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getRemainingRent = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllRemainingRents();
      setRemainingRent(data?.remainingAmount);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getDiscounts = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllDiscounts();
      setDiscounts(data?.discount);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getColumnData = async () => {
    setIsLoading(true);
    try {
      const { data } = await getGraphData();
      console.log({ data });
      setChartData(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getColumnData();
    getInItemsCount();
    getOutItemsCount();
    getTotalRent();
    getPaidRent();
    getRemainingRent();
    getDiscounts();
  }, []);

  return (
    <div className="flex flex-wrap w-full">
      <div className="w-full flex flex-wrap">
        <div className="w-1/2 p-3">
          <DashboardCard
            name={"Total In-Items (All time)"}
            count={`${inItemCount} crates`}
          />
        </div>
        <div className="w-1/2 p-3">
          <DashboardCard
            name="Total Out Items (All time)"
            count={outItemCount}
          />
        </div>
        <div className="w-1/2 p-3">
          <DashboardCard
            name={"Total Rents (All time)"}
            count={`Rs. ${totalRent}`}
          />
        </div>
        <div className="w-1/2 p-3">
          <DashboardCard
            name={"Total Paid Rents (All time)"}
            count={`Rs. ${paidRent}`}
          />
        </div>
        <div className="w-1/2 p-3">
          <DashboardCard
            name={"Total Remaining Rents (All time)"}
            count={`Rs. ${remainingRent}`}
          />
        </div>
        <div className="w-1/2 p-3">
          <DashboardCard
            name={"Total Discounts (All time)"}
            count={`Rs. ${discounts}`}
          />
        </div>
        <Divider />
        <div className="w-full p-3 text-left">
          <Typography.Text className="text-2xl font-semibold">
            Total Quantity
          </Typography.Text>
          <ColumnGraph data={chartData} />
        </div>
      </div>
      <div className="w-1/3 p-3"></div>
    </div>
  );
};

export default Dashboard;
