import {
  Badge,
  Button,
  ConfigProvider,
  Dropdown,
  Layout,
  Menu,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useProvideAuth from "../hooks/useAuth";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { TiThMenu } from "react-icons/ti";
import { LuLayoutDashboard } from "react-icons/lu";
import { PiUsers } from "react-icons/pi";
import logo from "../assets/logo.png";

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const AdminLayoutComponent = ({ children, pages, defaultOpenKey = "0" }) => {
  const [user, setUser] = useState();
  const auth = useProvideAuth();
  const primary = "#FFFFFF"; // Corrected initial color
  const [relatedColors, setRelatedColors] = useState({
    mainColor: primary,
    lighter: generateRelatedColorsWithTextContrast(primary).lighter,
    darker: generateRelatedColorsWithTextContrast(primary).darker,
    textContrastColor:
      generateRelatedColorsWithTextContrast(primary).textContrastColor,
    complementary: generateRelatedColorsWithTextContrast(primary).complementary,
  });

  const navigate = useNavigate();

  function generateRelatedColorsWithTextContrast(baseColor) {
    const hexToRgb = (hex) => {
      const bigint = parseInt(hex.slice(1), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    };

    const rgbToHex = (rgb) => {
      return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2])
        .toString(16)
        .slice(1)}`;
    };

    const adjustColorBrightness = (color, factor) => {
      const rgb = hexToRgb(color);
      const adjustedRgb = rgb.map((channel) =>
        Math.min(Math.max(Math.round(channel * factor), 0), 255)
      );
      return rgbToHex(adjustedRgb);
    };

    const calculateContrastRatio = (color1, color2) => {
      const luminance1 = calculateLuminance(hexToRgb(color1));
      const luminance2 = calculateLuminance(hexToRgb(color2));
      const contrast =
        (Math.max(luminance1, luminance2) + 0.05) /
        (Math.min(luminance1, luminance2) + 0.05);
      return contrast;
    };

    const calculateLuminance = (rgb) => {
      const [r, g, b] = rgb.map((channel) => {
        const channelValue = channel / 255;
        return channelValue <= 0.03928
          ? channelValue / 12.92
          : Math.pow((channelValue + 0.055) / 1.055, 2.4);
      });
      return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    const baseRgb = hexToRgb(baseColor);

    const mainColor = baseColor;
    const lighter = adjustColorBrightness(mainColor, 1.2);
    const containerBackground = adjustColorBrightness(mainColor, 7.5);
    const darker = adjustColorBrightness(mainColor, 0.7);

    const baseLuminance = calculateLuminance(baseRgb);
    let textContrastColor;
    let linkHoverColor;
    let logoPrim;
    let logoSec;

    if (baseLuminance > 0.2) {
      textContrastColor = "black";
      logoPrim = "black";
      logoSec = "#0866C5";
      linkHoverColor = "#7E7E7E";
    } else if (baseLuminance < 0.2) {
      textContrastColor = "white";
      linkHoverColor = "#ABABAB";
      logoPrim = "white";
      logoSec = "#aeb0f5";
    } else {
      textContrastColor =
        calculateContrastRatio(mainColor, lighter) >
        calculateContrastRatio(mainColor, darker)
          ? lighter
          : darker;
    }

    const complementary = rgbToHex([
      225 - baseRgb[0],
      225 - baseRgb[1],
      225 - baseRgb[2],
    ]);

    return {
      mainColor,
      lighter,
      containerBackground,
      darker,
      textContrastColor,
      complementary,
      linkHoverColor,
      logoPrim,
      logoSec,
    };
  }

  useEffect(() => {
    setRelatedColors(generateRelatedColorsWithTextContrast(primary));
  }, [primary]);

  useEffect(() => {
    setUser(auth.user);
  }, [auth.user]);

  const dropdownItems = [
    {
      key: "1",
      icon: (
        <LuLayoutDashboard
          style={{ color: primary === "#FFFFFF" ? "black" : "white" }}
        />
      ),
      label: (
        <Link to="/admin/dashboard">
          <div className="flex items-center justify-between px-2">
            <Text>Dashboard</Text>{" "}
            <Badge
              className="site-badge-count-109"
              count={0}
              style={{ backgroundColor: "#c3ebda", color: "white" }}
            />
          </div>
        </Link>
      ),
      style:
        window.location.pathname === "/admin/dashboard"
          ? { backgroundColor: "#c3ebda", color: "white" }
          : null,
    },
    {
      key: "2",
      icon: (
        <PiUsers style={{ color: primary === "#FFFFFF" ? "black" : "white" }} />
      ),
      label: (
        <Link to={"/admin/input-items"}>
          <div className="flex items-center justify-between px-2">
            <Text>Input Items</Text>{" "}
          </div>
        </Link>
      ),
      style:
        window.location.pathname === "/admin/input-items" ||
        window.location.pathname === "/admin/input-item/create" ||
        window.location.pathname === "/admin/input-item/edit"
          ? { backgroundColor: "#c3ebda", color: "white" }
          : null,
    },
    {
      key: "3",
      icon: (
        <LuLayoutDashboard
          style={{ color: primary === "#FFFFFF" ? "black" : "white" }}
        />
      ),
      label: (
        <Link to="/admin/output-items">
          <div className="flex items-center justify-between px-2">
            <Text>Output Items</Text>{" "}
          </div>
        </Link>
      ),
      style:
        window.location.pathname === "/admin/output-items" ||
        window.location.pathname === "/admin/output-item/create" ||
        window.location.pathname === "/admin/output-item/edit"
          ? { backgroundColor: "#c3ebda", color: "white" }
          : null,
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: primary === "#FFFFFF" ? "#ebebf2" : relatedColors.darker,
          colorBgContainer: primary,
          colorLink: primary === "#FFFFFF" ? "black" : "white",
          colorLinkHover: relatedColors.linkHoverColor,
          colorText: primary === "#FFFFFF" ? "black" : "white",
          colorSuccessText: "red",
          colorTextLabel: primary === "#FFFFFF" ? "black" : "white",
          colorTextBase: primary === "#FFFFFF" ? "black" : "white",
          colorTextSecondary: primary === "#FFFFFF" ? "black" : "white",
          colorTextHeading: primary === "#FFFFFF" ? "black" : "white",
          colorTextTertiary: primary === "#FFFFFF" ? "black" : "white",
          colorTextDescription: primary === "#FFFFFF" ? "black" : "white",
          colorIcon: primary === "#FFFFFF" ? "black" : "white",
          colorIconHover: relatedColors.textContrastColor,
          colorPrimaryHover: primary === "#FFFFFF" ? "#58ad89" : "white",
          colorPrimary: primary === "#FFFFFF" ? "#58ad89" : "white",
        },
      }}
    >
      <Layout hasSider={isMobile ? false : true}>
        <BrowserView>
          <Sider
            style={{
              backgroundColor: primary,
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
            }}
            width={300}
            trigger={null}
            collapsible
            collapsed={false}
          >
            <div className="h-20 py-2 flex justify-center items-center">
              <img className="h-full" src={logo} alt="asia cold storage" />
            </div>
            <Menu
              className="py-5"
              style={{ backgroundColor: "rgba(0,0,0,0)" }}
              mode="inline"
              defaultOpenKeys={[defaultOpenKey]}
              items={pages}
            />
          </Sider>
        </BrowserView>
        <div
          style={{
            marginLeft: isMobile ? 0 : 300,
            width: "100%",
            height: "100vh",
          }}
        >
          <Layout>
            <Header
              className="w-full flex justify-between items-center h-20"
              style={{
                padding: "0 40px 0 0",
                background: primary,
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {isMobile ? (
                <Dropdown
                  menu={{
                    items: dropdownItems,
                  }}
                >
                  <div className="ml-5">
                    <TiThMenu size={18} />{" "}
                  </div>
                </Dropdown>
              ) : (
                <div />
              )}
              <div className="flex items-center">
                <Typography.Text className="text-md">
                  {" "}
                  {user && user.firstName && user.lastName
                    ? user.firstName + " " + user.lastName
                    : "Loading..."}
                </Typography.Text>
                <div className="mx-3">
                  <Button
                    size="large"
                    type="link"
                    onClick={() => {
                      auth.signOut(() => navigate("/"));
                    }}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </Header>
            <Content
              style={{
                padding: isMobile ? 0 : 24,
                minHeight: "90.1vh",
                background:
                  primary === "#FFFFFF" ? "#ebebf2" : relatedColors.lighter,
              }}
            >
              <div className="">{children}</div>
            </Content>
          </Layout>
        </div>
      </Layout>
    </ConfigProvider>
  );
};

export default AdminLayoutComponent;
