import React from "react";
import { Space, Table, Tag } from "antd";

const AdminsTable = ({ columns, data, rowSelection, onChange, pagination }) => (
  <Table
    rowSelection={rowSelection}
    columns={columns}
    dataSource={data}
    onChange={onChange}
    pagination={pagination}
  />
);
export default AdminsTable;
