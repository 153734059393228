import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd";
import AdminsTable from "../../../components/Tables/AdminsTable";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  blockUser,
  createTransaction,
  deleteOutItemById,
  deleteUserById,
  filterAllCompanyAdmins,
  getAllCompaniesData,
  getAllCompanyAdmins,
  getAllOutItems,
  getTransaction,
  unBlockUser,
} from "../../../services/api";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterFilled,
  FilterOutlined,
} from "@ant-design/icons";
import { StyledLoader } from "../../../components/loader";
import DeleteModal from "../../../components/Modals/DeleteModal";
import CustomFilterDropdown from "../../../components/CustomFilterDropdown";
import { debounce } from "lodash";
import { AiOutlineTransaction } from "react-icons/ai";
import TransactionModal from "../../../components/Modals/TransactionModal";
import ViewTransactionsModal from "../../../components/Modals/ViewTransactionsModal";
import { isMobile } from "react-device-detect";

const { Text } = Typography;
const OutputItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewTransactionModalOpen, setIsViewTransactionModalOpen] =
    useState(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [allOutItems, setAllOutItems] = useState([]);
  const [selectedCompaniesAdmin, setSelectedCompaniesAdmin] = useState([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [resetIsDisabled, setResetIsDisabled] = useState(true);
  const [deleteID, setDeleteID] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTransactions, setSelectedTransactions] = useState(null);
  const [selectedViewTransaction, setViewTransaction] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You might not know this until you fetch the first set of data
  });
  const navigate = useNavigate();

  const handleDeleteModal = (id) => {
    setDeleteID(id);
    setIsModalOpen(true);
  };

  const handleTransactionModal = (item) => {
    setSelectedItem(item);
    setIsTransactionModalOpen(true);
  };

  const okViewTransaction = () => {
    setViewTransaction(null);
    setIsViewTransactionModalOpen(false);
  };

  const handleViewTransaction = async (record) => {
    setIsLoading(true);
    setViewTransaction(record);
    try {
      setIsViewTransactionModalOpen(true);
      const { data } = await getTransaction(record?.exitNumber);
      setSelectedTransactions(data?.transaction);
    } catch (error) {
      console.log({ error });
      message.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Exit Number",
      dataIndex: "exitNumber",
      key: "exitNumber",
      render: (text, record) => (
        <Text className="flex justify-between items-center">
          {text || "--"}{" "}
          <span>
            <Tooltip title="View Transactions">
              <Button
                onClick={() => handleViewTransaction(record)}
                icon={<EyeOutlined />}
                type="link"
              ></Button>
            </Tooltip>
          </span>
        </Text>
      ),
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Time Spent",
      dataIndex: "timeSpent",
      key: "timeSpent",
      render: (text) => <Text>{text + " Days" || "--"}</Text>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      render: (text) => <Text>{"Rs. " + text || "--"}</Text>,
    },
    {
      title: "Paid",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text) => <Text>{"Rs. " + text || "--"}</Text>,
    },
    {
      title: "Remaining",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
      render: (text) => <Text>{"Rs. " + text || "--"}</Text>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Rent",
      dataIndex: "rentPerMonth",
      key: "rentPerMonth",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Labour Cost",
      dataIndex: "labourCost",
      key: "labourCost",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            {/* <Tooltip title="Edit">
              <EditOutlined
                className="hover"
                onClick={() =>
                  navigate(`/admin/output-item/edit?id=${record?._id}`)
                }
              />
            </Tooltip> */}
            <Tooltip title="Delete">
              <DeleteOutlined
                className="hover"
                onClick={() => handleDeleteModal(record?._id)}
              />
            </Tooltip>
            <Tooltip title="Transaction">
              <AiOutlineTransaction
                className="hover mt-1 cursor-pointer"
                onClick={() => handleTransactionModal(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      title: "E.No",
      dataIndex: "exitNumber",
      key: "exitNumber",
      render: (text, record) => (
        <Text className="flex justify-between items-center">
          {text || "--"}{" "}
          <span>
            <Tooltip title="View Transactions">
              <Button
                onClick={() => handleViewTransaction(record)}
                icon={<EyeOutlined />}
                type="link"
              ></Button>
            </Tooltip>
          </span>
        </Text>
      ),
    },
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            {/* <Tooltip title="Edit">
              <EditOutlined
                className="hover"
                onClick={() =>
                  navigate(`/admin/output-item/edit?id=${record?._id}`)
                }
              />
            </Tooltip> */}
            <Tooltip title="Delete">
              <DeleteOutlined
                className="hover"
                onClick={() => handleDeleteModal(record?._id)}
              />
            </Tooltip>
            <Tooltip title="Transaction">
              <AiOutlineTransaction
                className="hover mt-1 cursor-pointer"
                onClick={() => handleTransactionModal(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  const data = [
    ...allOutItems.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        exitNumber: item?.exitNumber,
        item: item?.item,
        serialNumber: item?.serialNumber,
        timeSpent: item?.timeSpent < 30 ? 30 : item?.timeSpent,
        totalCost: item?.totalCost,
        labourCost: item?.labourCost,
        paidAmount: item?.paidAmount,
        remainingAmount: item?.remainingAmount,
        quantity: item?.quantity,
        discount: item?.discount,
        rentPerMonth: item?.rentPerMonth,
        driver: item?.driver,
        vehicalNumber: item?.vehicalNumber,
      };
    }),
  ];

  const handleTableChange = (pagination) => {
    getAllOutItemsData(pagination?.current, pagination?.pageSize, searchKey);
  };

  const getAllOutItemsData = async (page = 1, limit = 10, searchKey = "") => {
    setIsLoading(true);
    try {
      const { data } = await getAllOutItems(page, limit, searchKey);
      setAllOutItems(data?.outItems);
      setPagination((prev) => ({
        ...prev,
        total: data?.totalItems,
        current: page,
      }));
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllOutItemsData();
  }, []);

  const deleteRecord = async () => {
    setIsModalOpen(false);
    try {
      const index = allOutItems.findIndex((ind) => ind._id === deleteID);
      const arr = [...allOutItems];
      arr.splice(index, 1);
      setAllOutItems(arr);
      message.success("Input item deleted!");
      await deleteOutItemById(deleteID);
      setDeleteID(null);
      getAllOutItemsData();
    } catch (error) {
      console.log({ error });

      message.success("Error deleting input item!");
    }
  };

  const debouncedHandleSearch = useCallback(
    debounce(
      (searchValue) => {
        getAllOutItemsData(
          pagination?.current,
          pagination?.pageSize,
          searchValue
        );
      },
      1000,
      []
    )
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchKey(searchValue);
    debouncedHandleSearch(searchValue);
  };

  const confirmTransaction = async (values) => {
    setIsLoading(true);
    try {
      const orderData = {
        paidAmount: parseInt(values?.paidAmount),
        serialNumber: selectedItem?.serialNumber,
      };
      const { data } = await createTransaction(selectedItem?._id, orderData);
      getAllOutItemsData();
      setIsTransactionModalOpen(false);
    } catch (error) {
      message.error(error?.response?.data.error);
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center pl-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Output Items
          </Text>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={isMobile ? 24 : 8}>
            <Input
              size="large"
              value={searchKey}
              onChange={(e) => handleSearch(e)}
              placeholder="Search name..."
            />
          </Col>

          <Col span={8}>
            {/* <Row justify={"end"} gutter={12}>
              <Col>
                <Select
                  placeholder="Filter Status"
                  size="large"
                  style={{
                    width: 130,
                  }}
                  onChange={handleStatusSelectChange}
                  value={selectedStatusFilter ? selectedStatusFilter[0] : null}
                  options={[
                    {
                      value: "Blocked",
                      label: "Blocked",
                    },
                    {
                      value: "Un Blocked",
                      label: "Un Blocked",
                    },
                  ]}
                />
              </Col>
              <Col>
                <Button
                  disabled={resetIsDisabled}
                  size="large"
                  onClick={resetFilters}
                >
                  Reset Filters
                </Button>
              </Col>
            </Row> */}
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AdminsTable
          onChange={handleTableChange}
          columns={isMobile ? columnsMobile : columns}
          data={data}
          pagination={pagination}
        />
        <DeleteModal
          title="Delete record"
          text="Are you sure you want to delete this record?"
          deleteAction={deleteRecord}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        <TransactionModal
          title="Transaction"
          action={confirmTransaction}
          isModalOpen={isTransactionModalOpen}
          setIsModalOpen={setIsTransactionModalOpen}
        />
        <ViewTransactionsModal
          title="Transactions"
          action={okViewTransaction}
          isModalOpen={isViewTransactionModalOpen}
          data={{
            ...selectedViewTransaction,
            transactions: selectedTransactions,
          }}
          setIsModalOpen={setIsViewTransactionModalOpen}
        />
      </Card>
    </div>
  );
};

export default OutputItems;
