// ProtectedRoutes.js
import React, { useEffect, useState, useContext } from "react";
import { Route, Navigate, Routes, useLocation, Outlet } from "react-router-dom";
// import { getAccount } from "../services/api";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Dashboard from "../pages/Admin/Dashboard";
import { AuthContext } from "../context/Auth";
import AdminLayout from "../layout/AdminLayout";
import InputItems from "../pages/Admin/Items/InputItems";
import CreateInputItems from "../pages/Admin/Items/CreateInputItems";
import EditInputItems from "../pages/Admin/Items/EditInputItems";
import OutputItems from "../pages/Admin/OutputItems/OutputItems";
import CreateOutputItems from "../pages/Admin/OutputItems/CreateOutputItems";
import EditOutputItems from "../pages/Admin/OutputItems/EditOutputItems";
const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const PrivateRoute = () => {
  const { user } = useContext(AuthContext);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return user ? <Outlet /> : <Navigate to="/login" />;
};

const ProtectedRoutes = () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    user?.roles?.map((role) => {
      if (role === "SUPER_ADMIN") {
        setIsSuperAdmin(true);
      } else if (role === "COMPANY_ADMIN") {
        setIsCompanyAdmin(true);
      } else {
        setIsUser(true);
      }
      return null;
    });
  }, [isSuperAdmin, isCompanyAdmin, isUser]);
  return (
    // isSuperAdmin ?
    <Routes>
      {/* Admin Routes */}
      <Route exact path="/admin" element={<PrivateRoute />}>
        <Route
          exact
          path="/admin"
          element={
            <AdminLayout>
              <Dashboard />
            </AdminLayout>
          }
        />
      </Route>
      <Route exact path="/admin/dashboard" element={<PrivateRoute />}>
        <Route
          exact
          path="/admin/dashboard"
          element={
            <AdminLayout>
              <Dashboard />
            </AdminLayout>
          }
        />
      </Route>

      <Route exact path="/admin/input-items" element={<PrivateRoute />}>
        <Route
          exact
          path="/admin/input-items"
          element={
            <AdminLayout>
              <InputItems />
            </AdminLayout>
          }
        />
      </Route>
      <Route exact path="/admin/input-item/create" element={<PrivateRoute />}>
        <Route
          exact
          path="/admin/input-item/create"
          element={
            <AdminLayout>
              <CreateInputItems />
            </AdminLayout>
          }
        />
      </Route>
      <Route exact path="/admin/input-item/edit" element={<PrivateRoute />}>
        <Route
          exact
          path="/admin/input-item/edit"
          element={
            <AdminLayout>
              <EditInputItems />
            </AdminLayout>
          }
        />
      </Route>
      <Route exact path="/admin/output-items" element={<PrivateRoute />}>
        <Route
          exact
          path="/admin/output-items"
          element={
            <AdminLayout>
              <OutputItems />
            </AdminLayout>
          }
        />
      </Route>
      <Route exact path="/admin/output-item/create" element={<PrivateRoute />}>
        <Route
          exact
          path="/admin/output-item/create"
          element={
            <AdminLayout>
              <CreateOutputItems />
            </AdminLayout>
          }
        />
      </Route>
      <Route exact path="/admin/output-item/edit" element={<PrivateRoute />}>
        <Route
          exact
          path="/admin/output-item/edit"
          element={
            <AdminLayout>
              <EditOutputItems />
            </AdminLayout>
          }
        />
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
