import AdminLayoutComponent from "../components/AdminLayoutComponent";
import { LuLayoutDashboard, LuScrollText } from "react-icons/lu";
import { PiUsers } from "react-icons/pi";
import { GrUserAdmin } from "react-icons/gr";
import { RiAdminLine } from "react-icons/ri";
import { SiAwsorganizations } from "react-icons/si";
import { TbTags } from "react-icons/tb";
import { MdOutlineAssessment, MdOutlineFeedback } from "react-icons/md";
import { Link } from "react-router-dom";
import { Badge, Typography } from "antd";
import { useState, useEffect } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { LuLayoutTemplate } from "react-icons/lu";
import { useSelector } from "react-redux";

const { Text } = Typography;
const primary = "#FFFFFF";
const allPages = [
  {
    key: "1",
    icon: (
      <LuLayoutDashboard
        style={{ color: primary === "#FFFFFF" ? "black" : "white" }}
      />
    ),
    label: (
      <Link to="/admin/dashboard">
        <div className="flex items-center justify-between px-2">
          <Text>Dashboard</Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#c3ebda", color: "white" }}
          />
        </div>
      </Link>
    ),
    style:
      window.location.pathname === "/admin/dashboard"
        ? { backgroundColor: "#c3ebda", color: "white" }
        : null,
  },
  {
    key: "2",
    icon: (
      <PiUsers style={{ color: primary === "#FFFFFF" ? "black" : "white" }} />
    ),
    label: (
      <Link to={"/admin/input-items"}>
        <div className="flex items-center justify-between px-2">
          <Text>Input Items</Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#1420ff", color: "white" }}
          />
        </div>
      </Link>
    ),
    style:
      window.location.pathname === "/admin/input-items" ||
      window.location.pathname === "/admin/input-item/create" ||
      window.location.pathname === "/admin/input-item/edit"
        ? { backgroundColor: "#c3ebda", color: "white" }
        : null,
  },
  {
    key: "3",
    icon: (
      <LuLayoutDashboard
        style={{ color: primary === "#FFFFFF" ? "black" : "white" }}
      />
    ),
    label: (
      <Link to="/admin/output-items">
        <div className="flex items-center justify-between px-2">
          <Text>Output Items</Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#c3ebda", color: "white" }}
          />
        </div>
      </Link>
    ),
    style:
      window.location.pathname === "/admin/output-items" ||
      window.location.pathname === "/admin/output-item/create" ||
      window.location.pathname === "/admin/output-item/edit"
        ? { backgroundColor: "#c3ebda", color: "white" }
        : null,
  },
];
const AdminLayout = ({ children }) => {
  // const [primary] = useState("#FFFFFF");
  const [defaultOpenKey, setDefaultOpenKey] = useState("2");
  const [pages, setPages] = useState([...allPages]);
  const { user } = useSelector((state) => state.auth);

  return (
    <AdminLayoutComponent
      children={children}
      pages={pages}
      defaultOpenKey={defaultOpenKey}
    />
  );
};

export default AdminLayout;
