import React, { useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";
import moment from "moment";

const { Text } = Typography;

const ConfirmOutModal = ({
  isModalOpen,
  setIsModalOpen,
  action,
  title = "Out Item",
  orderData,
}) => {
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    action({
      ...values,
      ...orderData,
      timeSpent: DaysFromSpecificDate(orderData?.sendItemDetails?.createdAt),
    });
    form.setFieldsValue({
      exitNumber: "",
      paidAmount: "",
      quantity: "",
      rent: "",
      labourCost: "",
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const findTotalCost = () => {
    const timeSpent = parseInt(
      DaysFromSpecificDate(orderData?.sendItemDetails?.createdAt)
    );
    console.log({ timeSpent });
    const rent = parseInt(orderData?.rent);
    const labourCost = parseInt(orderData?.labourCost);
    const quantity = parseInt(orderData?.quantity);

    const fullMonths = Math.floor(timeSpent / 30);
    const daysOver = timeSpent % 30;

    // Rent per week calculation
    const rentPerWeek = rent / 4;

    let rentCost;

    // Calculate rent based on days over full months
    if (daysOver === 0) {
      // Exact multiple of 30 days, charge per full month
      rentCost = rent * fullMonths;
      console.log({ rentCost });
    } else {
      // Days not forming a full month
      if (daysOver < 9) {
        rentCost = rent * fullMonths + rentPerWeek;
      } else if (daysOver < 17) {
        rentCost = rent * fullMonths + rentPerWeek * 2;
      } else if (daysOver < 24) {
        rentCost = rent * fullMonths + rentPerWeek * 3;
      } else {
        // Charge an additional full month if over 23 days
        rentCost = rent * (fullMonths + 1);
      }
    }

    const rentAndLabourCost = rentCost + labourCost;
    const totalCost = rentAndLabourCost * quantity;
    return totalCost;
  };

  function DaysFromSpecificDate(date) {
    const startDate = moment(date); // use YYYY-MM-DD format
    const today = moment(); // current date and time
    const duration = today.diff(startDate, "days"); // calculates days between dates

    return duration < 30 ? 30 : duration;
  }

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        footer={false}
        width={700}
        onCancel={handleCancel}
      >
        <Divider />
        <div>
          <Form
            name="basic"
            layout="vertical"
            className="w-full"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex flex-wrap items-center justify-between">
              <Typography.Text className="w-1/3">
                Serial Number:
                <span className="font-bold ml-2">
                  {orderData?.serialNumber || "-- --"}
                </span>
              </Typography.Text>
              <Typography.Text className="w-1/3">
                Exit Number:
                <span className="font-bold ml-2">
                  {orderData?.exitNumber || "-- --"}
                </span>
              </Typography.Text>
              <Typography.Text className="w-1/3">
                Quantity:
                <span className="font-bold ml-2">
                  {orderData?.quantity || "-- --"}
                </span>
              </Typography.Text>
              <Typography.Text className="w-1/3 mt-4">
                Rent (Monthly):
                <span className="font-bold ml-2">
                  {orderData?.rent || "-- --"}
                </span>
              </Typography.Text>
              <Typography.Text className="w-1/3 mt-4">
                Time spent:
                <span className="font-bold ml-2">
                  {DaysFromSpecificDate(orderData?.sendItemDetails?.createdAt)}
                </span>
              </Typography.Text>
              <Typography.Text className="w-1/3 mt-4">
                Labour Cost:
                <span className="font-bold ml-2">
                  {orderData?.labourCost || "-- --"}
                </span>
              </Typography.Text>
            </div>
            <Divider />
            <Typography.Text className="text-lg">
              Total cost:
              <span className="font-bold ml-2">{findTotalCost()}</span>
            </Typography.Text>
            <Divider />
            <div className="flex flex-wrap">
              <Form.Item
                label="Paid Amount"
                name="paidAmount"
                className="w-1/2 px-2"
                rules={[
                  {
                    required: true,
                    message: "Please input paid amount!",
                  },
                ]}
              >
                <Input type="number" size="large" />
              </Form.Item>
              <Form.Item
                label="Discount"
                name="discount"
                className="w-1/2 px-2"
                rules={[
                  {
                    required: true,
                    message: "Please input discount!",
                  },
                ]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </div>
            <Row justify={"end"} gutter={12}>
              <Col>
                <Button htmlType="submit" size="large" type="primary">
                  Confirm
                </Button>
              </Col>
              <Col>
                <Button size="large" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default ConfirmOutModal;
