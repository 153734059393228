import { Column } from "@ant-design/plots";
import React from "react";
import ReactDOM from "react-dom";

const ColumnGraph = ({ data }) => {
  const config = {
    data: data,
    xField: "time",
    yField: "count",
    colorField: "name",
    group: true,
    style: {
      inset: 5,
    },
  };
  return <Column {...config} />;
};

export default ColumnGraph;
