import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd";
import AdminsTable from "../../../components/Tables/AdminsTable";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  blockUser,
  createOutItem,
  deleteInItemById,
  deleteUserById,
  filterAllCompanyAdmins,
  getAllCompaniesData,
  getAllCompanyAdmins,
  getAllInItems,
  getOutItemsBySno,
  getTransactionBySno,
  unBlockUser,
} from "../../../services/api";
import {
  DeleteOutlined,
  EditOutlined,
  FilterFilled,
  FilterOutlined,
  LogoutOutlined,
  PrinterOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { FiSend } from "react-icons/fi";
import { StyledLoader } from "../../../components/loader";
import DeleteModal from "../../../components/Modals/DeleteModal";
import CustomFilterDropdown from "../../../components/CustomFilterDropdown";
import { debounce } from "lodash";
import SendOutModal from "../../../components/Modals/SendOutModal";
import ConfirmOutModal from "../../../components/Modals/ConfirmOutModal";
import PrintInItemsModal from "../../../components/Modals/PrintInItemsModal";
import { isMobile } from "react-device-detect";

const { Text } = Typography;
const InputItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isSendOutModalOpen, setIsSendOutModalOpen] = useState(false);
  const [confirmOutModal, setConfirmOutModal] = useState(false);
  const [allInItems, setAllInItems] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [resetIsDisabled, setResetIsDisabled] = useState(true);
  const [confirmOutData, setConfirmOutData] = useState(null);
  const [deleteID, setDeleteID] = useState(null);
  const [sendOutID, setSendOutID] = useState(null);
  const [sendOutItem, setSendOutItem] = useState(null);
  const [sendOutSerialNumber, setSendOutSerialNumber] = useState(null);
  const [inItemPrint, setInItemPrint] = useState(null);
  const [selectedPrint, setSelectedPrint] = useState(null);
  const [selectedOutItems, setSelectedOutItems] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You might not know this until you fetch the first set of data
  });
  const navigate = useNavigate();

  const handleDeleteModal = (id) => {
    setDeleteID(id);
    setIsModalOpen(true);
  };

  const handleSendOutModal = (item) => {
    setSendOutItem(item);
    setSendOutID(item._id);
    setSendOutSerialNumber(item.serialNumber);
    setIsSendOutModalOpen(true);
  };

  const okViewTransaction = () => {
    setInItemPrint(null);
    setIsPrintModalOpen(false);
  };

  const handleViewTransaction = async (record) => {
    setIsLoading(true);
    setSelectedPrint(record);

    try {
      setIsPrintModalOpen(true);
      const { data } = await getOutItemsBySno(record?.serialNumber);
      setSelectedOutItems(data?.outitems);
    } catch (error) {
      console.log({ error });
      message.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record) => (
        <Text className="flex justify-between items-center">
          {text || "--"}{" "}
          <span>
            <Tooltip title="Print">
              <Button
                onClick={() => handleViewTransaction(record)}
                icon={<PrinterOutlined />}
                type="link"
              ></Button>
            </Tooltip>
          </span>
        </Text>
      ),
      // sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text || "--"}</Text>,
      // sorter: true,
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      render: (text) => <Text>{text || "--"}</Text>,
      // sorter: true,
    },
    {
      title: "Total Quantity",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      render: (text) => <Text>{text}</Text>,
      // sorter: true,
    },
    {
      title: "Remaining Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <Text>{text}</Text>,
      // sorter: true,
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      render: (text) => <Text>{text || "--"}</Text>,
      // sorter: true,
    },
    {
      title: "Marka",
      dataIndex: "marka",
      key: "marka",
      render: (text) => <Text>{text || "--"}</Text>,
      // sorter: true,
    },
    {
      title: "Room Number",
      dataIndex: "roomNumber",
      key: "roomNumber",
      render: (text) => <Text>{text || "--"}</Text>,
      // sorter: true,
    },
    {
      title: "Ring Number",
      dataIndex: "ringNumber",
      key: "ringNumber",
      render: (text) => <Text>{text || "--"}</Text>,
      // sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Tooltip title="Edit">
              <EditOutlined
                className="hover"
                onClick={() =>
                  navigate(`/admin/input-item/edit?id=${record?._id}`)
                }
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                className="hover"
                onClick={() => handleDeleteModal(record?._id)}
              />
            </Tooltip>
            <Tooltip title="Exit">
              <LogoutOutlined
                className="hover"
                onClick={() => handleSendOutModal(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record) => (
        <Text className="flex justify-between items-center">
          {text || "--"}{" "}
          <span>
            <Tooltip title="Print">
              <Button
                onClick={() => handleViewTransaction(record)}
                icon={<PrinterOutlined />}
                type="link"
              ></Button>
            </Tooltip>
          </span>
        </Text>
      ),
      // sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text || "--"}</Text>,
      // sorter: true,
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      render: (text) => <Text>{text || "--"}</Text>,
      // sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Tooltip title="Edit">
              <EditOutlined
                className="hover"
                onClick={() =>
                  navigate(`/admin/input-item/edit?id=${record?._id}`)
                }
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                className="hover"
                onClick={() => handleDeleteModal(record?._id)}
              />
            </Tooltip>
            <Tooltip title="Exit">
              <LogoutOutlined
                className="hover"
                onClick={() => handleSendOutModal(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  const data = [
    ...allInItems.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        name: item?.name,
        marka: item?.marka,
        item: item?.item,
        ringNumber: item?.ringNumber,
        roomNumber: item?.roomNumber,
        serialNumber: item?.serialNumber,
        vehicalNumber: item?.vehicalNumber,
        driver: item?.driver,
        quantity: item?.quantity,
        totalQuantity: item?.totalQuantity,
        condition: item?.condition,
        createdAt: item?.createdAt,
      };
    }),
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    getAllInItemsData(pagination?.current, pagination?.pageSize, searchKey);
  };

  const confirmOutItem = (values) => {
    const data = {
      ...values,
      item: sendOutID,
      serialNumber: sendOutSerialNumber,
      sendItemDetails: sendOutItem,
    };
    console.log({ data });
    setConfirmOutModal(true);
    setConfirmOutData(data);
  };

  const confirmSendOutItem = async (values) => {
    setIsLoading(true);
    try {
      const orderData = {
        item: values?.item,
        serialNumber: values?.serialNumber,
        exitNumber: values?.exitNumber,
        paidAmount: parseInt(values?.paidAmount),
        rent: parseInt(values?.rent),
        quantity: parseInt(values?.quantity),
        labourCost: parseInt(values?.labourCost),
        timeSpent: parseFloat(values?.timeSpent),
        discount: parseInt(values?.discount),
        vehicalNumber: values?.vehicalNumber,
        driver: values?.driver,
      };
      const { data } = await createOutItem(orderData);
      getAllInItemsData();
      setIsSendOutModalOpen(false);
      setConfirmOutModal(false);
    } catch (error) {
      message.error(error?.response?.data.message);
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusSelectChange = (value) => {
    setResetIsDisabled(false);
    setSelectedStatusFilter([value]);
  };

  const getAllInItemsData = async (page = 1, limit = 10, searchKey = "") => {
    setIsLoading(true);
    try {
      const { data } = await getAllInItems(page, limit, searchKey);
      setAllInItems(data?.inItems);
      setPagination((prev) => ({
        ...prev,
        total: data?.totalItems,
        current: page,
      }));
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllInItemsData();
  }, []);

  const deleteRecord = async () => {
    setIsModalOpen(false);
    try {
      const index = allInItems.findIndex((ind) => ind._id === deleteID);
      const arr = [...allInItems];
      arr.splice(index, 1);
      setAllInItems(arr);
      message.success("Input item deleted!");
      await deleteInItemById(deleteID);
      setDeleteID(null);
      getAllInItemsData();
    } catch (error) {
      console.log({ error });

      message.success("Error deleting input item!");
    }
  };

  const debouncedHandleSearch = useCallback(
    debounce(
      (searchValue) => {
        getAllInItemsData(
          pagination?.current,
          pagination?.pageSize,
          searchValue
        );
      },
      1000,
      []
    )
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchKey(searchValue);
    setResetIsDisabled(false);
    debouncedHandleSearch(searchValue);
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center pl-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Input Items
          </Text>
          <Button
            size="large"
            onClick={() => navigate("/admin/input-item/create")}
          >
            Add New +
          </Button>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={isMobile ? 24 : 8}>
            <Input
              size="large"
              value={searchKey}
              onChange={(e) => handleSearch(e)}
              placeholder="Search name..."
            />
          </Col>
          <Col span={8}></Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AdminsTable
          onChange={handleTableChange}
          columns={isMobile ? columnsMobile : columns}
          data={data}
          pagination={pagination}
        />
        <DeleteModal
          title="Delete record"
          text="Are you sure you want to delete this record?"
          deleteAction={deleteRecord}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        <SendOutModal
          title="Out Item"
          action={confirmOutItem}
          isModalOpen={isSendOutModalOpen}
          setIsModalOpen={setIsSendOutModalOpen}
        />
        <ConfirmOutModal
          title="Out Item"
          action={confirmSendOutItem}
          isModalOpen={confirmOutModal}
          orderData={confirmOutData}
          setIsModalOpen={setConfirmOutModal}
        />
        <PrintInItemsModal
          title="In-Item"
          action={okViewTransaction}
          isModalOpen={isPrintModalOpen}
          data={{ ...selectedPrint, outitems: selectedOutItems }}
          setIsModalOpen={setIsPrintModalOpen}
        />
      </Card>
    </div>
  );
};

export default InputItems;
