import React, { useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";

const { Text } = Typography;

const TransactionModal = ({
  isModalOpen,
  setIsModalOpen,
  action,
  title = "Out Item",
}) => {
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    action(values);
    form.setFieldsValue({
      paidAmount: "",
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        footer={false}
        width={700}
        onCancel={handleCancel}
      >
        <Divider />
        <div>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            className="w-full"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex flex-wrap">
              <Form.Item
                label="Paid Amount"
                name="paidAmount"
                className="w-1/2 px-2"
                rules={[
                  {
                    required: true,
                    message: "Please input paid amount!",
                  },
                ]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </div>
            <Row justify={"end"} gutter={12}>
              <Col>
                <Button htmlType="submit" size="large" type="primary">
                  Confirm
                </Button>
              </Col>
              <Col>
                <Button size="large" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default TransactionModal;
