import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";
import moment from "moment";
import logo from "./../../assets/logo.png";

const { Text } = Typography;

const PrintInItemsModal = ({
  isModalOpen,
  setIsModalOpen,
  action,
  title = "In-Item",
  data,
}) => {
  const [hideHtml, setHideHtml] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    action();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  console.log({ data });

  const findTotalCost = () => {
    const timeSpent = parseInt(
      DaysFromSpecificDate(data?.sendItemDetails?.createdAt)
    );
    const rent = parseInt(data?.rent);
    const labourCost = parseInt(data?.labourCost);
    const quantity = parseInt(data?.quantity);

    const fullMonths = Math.floor(timeSpent / 30);
    const daysOver = timeSpent % 30;

    // Rent per week calculation
    const rentPerWeek = rent / 4;

    let rentCost;

    // Calculate rent based on days over full months
    if (daysOver === 0) {
      // Exact multiple of 30 days, charge per full month
      rentCost = rent * fullMonths;
    } else {
      // Days not forming a full month
      if (daysOver < 9) {
        rentCost = rent * fullMonths + rentPerWeek;
      } else if (daysOver < 17) {
        rentCost = rent * fullMonths + rentPerWeek * 2;
      } else if (daysOver < 24) {
        rentCost = rent * fullMonths + rentPerWeek * 3;
      } else {
        // Charge an additional full month if over 23 days
        rentCost = rent * (fullMonths + 1);
      }
    }

    const rentAndLabourCost = rentCost + labourCost;
    const totalCost = rentAndLabourCost * quantity;
    return totalCost;
  };

  function DaysFromSpecificDate(date) {
    const startDate = moment(date); // use YYYY-MM-DD format
    const today = moment(); // current date and time
    const duration = today.diff(startDate, "days"); // calculates days between dates

    return duration;
  }

  const handlePrint = () => {
    setHideHtml(true);
    setTimeout(() => {
      window.print();
      setHideHtml(false);
    }, 300);
  };

  const outitemsTotals = data?.outitems
    ? data?.outitems.reduce(
        (acc, outitem) => {
          acc.totalCost += outitem.totalCost || 0;
          acc.paidAmount += outitem.paidAmount || 0;
          acc.remainingAmount += outitem.remainingAmount || 0;
          acc.discount += outitem.discount || 0;
          acc.quantity += outitem.quantity || 0;
          acc.rentPerMonth += outitem.rentPerMonth || 0;

          return acc;
        },
        {
          totalCost: 0,
          paidAmount: 0,
          remainingAmount: 0,
          discount: 0,
          quantity: 0,
          rentPerMonth: 0,
        }
      )
    : {
        totalCost: 0,
        paidAmount: 0,
        remainingAmount: 0,
        discount: 0,
        quantity: 0,
        rentPerMonth: 0,
      };

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        footer={false}
        width={"100%"}
        onCancel={handleCancel}
      >
        <div className="mt-3 text-right">
          <img className="w-32" src={logo} alt="asia cold storage" />
        </div>
        <Divider className="my-2" />
        <div>
          <Form
            name="basic"
            layout="vertical"
            className="w-full"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex flex-wrap items-center justify-between text-right">
              <Typography.Text className="text-lg w-1/2">
                <span className="font-bold mr-2">{data?.name || "-- --"}</span>:
                نام
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2">
                <span className="font-bold mr-2">
                  {data?.serialNumber || "-- --"}
                </span>
                : سیریل نمبر
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">{data?.item || "00"}</span>:
                آئٹم
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">
                  {data?.totalQuantity || "00"}
                </span>
                : کل مقدار
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">{data?.marka || "00"}</span>:
                مارکہ
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">{data?.quantity || "00"}</span>
                : باقی مقدار
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">
                  {data?.ringNumber || "00"}
                </span>
                : رنگ نمبر
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">
                  {data?.roomNumber || "00"}
                </span>
                : روم نمبر
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">
                  {data?.vehicalNumber || "00"}
                </span>
                : گاڑی نمبر
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">{data?.driver || "00"}</span>:
                ڈرائیور
              </Typography.Text>
              <Typography.Text className="text-lg w-1/2 mt-4">
                <span className="font-bold mr-2">
                  {data?.condition || "00"}
                </span>
                : کنڈیشن
              </Typography.Text>
            </div>
            <Divider />

            <div>
              <div className="font-bold flex justify-between">
                <div className="w-full text-left">
                  <Typography.Text className="text-lg">کل رقم</Typography.Text>
                </div>
                <div className="w-full text-center">
                  <Typography.Text className="text-lg">
                    ادا شدہ رقم
                  </Typography.Text>
                </div>
                <div className="w-full text-center">
                  <Typography.Text className="text-lg">
                    بقایا رقم
                  </Typography.Text>
                </div>
                <div className="w-full text-center">
                  <Typography.Text className="text-lg">رعایت</Typography.Text>
                </div>
                <div className="w-full text-center">
                  <Typography.Text className="text-lg">مقدار</Typography.Text>
                </div>
                <div className="w-full text-center">
                  <Typography.Text className="text-lg">کرایہ</Typography.Text>
                </div>
                <div className="text-right w-full">
                  <Typography.Text className="text-lg">تاریخ</Typography.Text>
                </div>
              </div>
              <Divider className="my-1" />
            </div>
            {data?.outitems?.map((item, i) => {
              return (
                <div key={i}>
                  <div className="flex justify-between">
                    <div className="w-full">
                      <Typography.Text className="text-lg">
                        {item.totalCost || "00"}
                      </Typography.Text>
                    </div>
                    <div className="text-center w-full">
                      <Typography.Text className="text-lg">
                        {" "}
                        {item.paidAmount || "00"}
                      </Typography.Text>
                    </div>
                    <div className="text-center w-full">
                      <Typography.Text className="text-lg">
                        {" "}
                        {item.remainingAmount || "00"}
                      </Typography.Text>
                    </div>
                    <div className="text-center w-full">
                      <Typography.Text className="text-lg">
                        {" "}
                        {item.discount || "00"}
                      </Typography.Text>
                    </div>
                    <div className="text-center w-full">
                      <Typography.Text className="text-lg">
                        {" "}
                        {item.quantity || "00"}
                      </Typography.Text>
                    </div>
                    <div className="text-center w-full">
                      <Typography.Text className="text-lg">
                        {" "}
                        {item.rentPerMonth || "00"}
                      </Typography.Text>
                    </div>
                    <div className="text-right w-full">
                      <Typography.Text className="text-lg">
                        {moment(item?.createdAt).format("h:m a, DD MMM YYYY")}
                      </Typography.Text>
                    </div>
                  </div>
                  <Divider className="my-1" />
                </div>
              );
            })}
            <div className="font-bold flex justify-between">
              <div className="w-full text-left">
                <Typography.Text className="text-lg">
                  {outitemsTotals?.totalCost}
                </Typography.Text>
              </div>
              <div className="w-full text-center">
                <Typography.Text className="text-lg">
                  {outitemsTotals?.paidAmount}
                </Typography.Text>
              </div>
              <div className="w-full text-center">
                <Typography.Text className="text-lg">
                  {outitemsTotals?.remainingAmount}
                </Typography.Text>
              </div>
              <div className="w-full text-center">
                <Typography.Text className="text-lg">
                  {outitemsTotals?.discount}
                </Typography.Text>
              </div>
              <div className="w-full text-center">
                <Typography.Text className="text-lg">
                  {outitemsTotals?.quantity}
                </Typography.Text>
              </div>
              <div className="w-full text-center">
                <Typography.Text className="text-lg">
                  {outitemsTotals?.rentPerMonth}
                </Typography.Text>
              </div>
              <div className="text-right w-full">
                <Typography.Text className="text-lg">ٹوٹل</Typography.Text>
              </div>
            </div>
            {hideHtml ? null : (
              <Row justify={"end"} gutter={12} className="mt-6">
                <Col>
                  <Button htmlType="submit" size="large">
                    Ok
                  </Button>
                  <Button
                    onClick={handlePrint}
                    size="large"
                    type="primary"
                    className="ml-2"
                  >
                    Print
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default PrintInItemsModal;
