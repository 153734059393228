import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiInstance } from "../../utils/constants";

export const getUserProfile = createAsyncThunk(
  "user/fetchProfile",
  async (id) => {
    console.log(id);
    const response = await apiInstance.get(`user/getByCognitoId/${id}`);
    return response.data;
  }
);
export const setUserProfile = createAsyncThunk("user/setUser", async (data) => {
  console.log(data);
  const response = await apiInstance.post(`user/addUser`, data);
  return response.data;
});

export const getChallengeDetails = createAsyncThunk(
  "get/assessment",
  async (data) => {
    const { companyId, userId, assessmentId } = data;
    console.log(companyId, userId, assessmentId);
    const response = await apiInstance.get(
      `result/${userId}/${assessmentId}/${companyId}`
    );
    return response.data;
  }
);
