import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";
import moment from "moment";
import logo from "./../../assets/logo.png";

const { Text } = Typography;

const ViewTransactionsModal = ({
  isModalOpen,
  setIsModalOpen,
  action,
  title = "Transactions",
  data,
}) => {
  const [hideHtml, setHideHtml] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    action();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  console.log({ data });

  const findTotalCost = () => {
    const timeSpent = parseInt(
      DaysFromSpecificDate(data?.sendItemDetails?.createdAt)
    );
    const rent = parseInt(data?.rent);
    const labourCost = parseInt(data?.labourCost);
    const quantity = parseInt(data?.quantity);

    const fullMonths = Math.floor(timeSpent / 30);
    const daysOver = timeSpent % 30;

    // Rent per week calculation
    const rentPerWeek = rent / 4;

    let rentCost;

    // Calculate rent based on days over full months
    if (daysOver === 0) {
      // Exact multiple of 30 days, charge per full month
      rentCost = rent * fullMonths;
    } else {
      // Days not forming a full month
      if (daysOver < 9) {
        rentCost = rent * fullMonths + rentPerWeek;
      } else if (daysOver < 17) {
        rentCost = rent * fullMonths + rentPerWeek * 2;
      } else if (daysOver < 24) {
        rentCost = rent * fullMonths + rentPerWeek * 3;
      } else {
        // Charge an additional full month if over 23 days
        rentCost = rent * (fullMonths + 1);
      }
    }

    const rentAndLabourCost = rentCost + labourCost;
    const totalCost = rentAndLabourCost * quantity;
    return totalCost;
  };

  function DaysFromSpecificDate(date) {
    const startDate = moment(date); // use YYYY-MM-DD format
    const today = moment(); // current date and time
    const duration = today.diff(startDate, "days"); // calculates days between dates

    return duration;
  }
  const columns = [
    {
      title: "Total Paid",
      dataIndex: "paidTotal",
      key: "paidTotal",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Remaining Amount",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
      render: (text) => <Text>{text || "--"}</Text>,
    },
  ];

  //   let tableData;
  //   useEffect(() => {
  //     tableData = [
  //       ...data?.transactions?.map((item, i) => {
  //         return {
  //           _id: item?._id,
  //           key: i + 1,
  //           paidTotal: item?.paidTotal,
  //           remainingAmount: item?.remainingAmount,
  //           paidAmount: item?.paidAmount,
  //           date: item?.createdAt,
  //         };
  //       }),
  //     ];
  //   }, [data.transactions]);

  const handlePrint = () => {
    setHideHtml(true);
    setTimeout(() => {
      window.print();
      setHideHtml(false);
    }, 300);
  };

  return (
    <>
      <Modal
        title={"کھاتہ"}
        open={isModalOpen}
        footer={false}
        width={700}
        onCancel={handleCancel}
      >
        <div className="mt-3 text-right">
          <img className="w-32" src={logo} alt="asia cold storage" />
        </div>
        <Divider className="my-2" />
        <div>
          <Form
            name="basic"
            layout="vertical"
            className="w-full"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex flex-wrap items-center justify-between text-right">
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">
                  {data?.item?.quantity || "00"}
                </span>
                : بقایا مقدار
              </Typography.Text>
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">
                  {data?.serialNumber || "-- --"}
                </span>
                : سیریل نمبر
              </Typography.Text>
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">
                  {data?.exitNumber || "-- --"}
                </span>
                : ایگزٹ نمبر
              </Typography.Text>
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">{data?.quantity || "00"}</span>
                : مقدار
              </Typography.Text>
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">
                  {data?.timeSpent || "00"}
                </span>
                : صرف شدہ وقت
              </Typography.Text>
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">
                  {data?.rentPerMonth || "00"}
                </span>
                : کرایہ
              </Typography.Text>
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">
                  {data?.labourCost || "00"}
                </span>
                : مزدوری
              </Typography.Text>
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">
                  {data?.vehicalNumber || "-- --"}
                </span>
                : گاڑی نمبر
              </Typography.Text>
              <Typography.Text className="text-lg w-1/3 mt-4">
                <span className="font-bold mr-2">
                  {data?.driver || "-- --"}
                </span>
                : وصول کنندہ
              </Typography.Text>
            </div>
            <Divider />
            <div>
              <div className="flex justify-between">
                <div className="w-full">
                  <Typography.Text className="font-bold text-lg">
                    ادا شدہ رقم
                  </Typography.Text>
                </div>
                <div className="text-center w-full">
                  <Typography.Text className="font-bold text-lg">
                    بقایا رقم
                  </Typography.Text>
                </div>
                <div className="text-right w-full">
                  <Typography.Text className="font-bold text-lg">
                    تاریخ
                  </Typography.Text>
                </div>
              </div>
              <Divider className="my-2" />
              {data?.transactions?.map((item, i) => {
                return (
                  <div key={i}>
                    <div className="flex justify-between">
                      <div className="w-full">
                        <Typography.Text className="text-lg">
                          {item.paidAmount || "00"}
                        </Typography.Text>
                      </div>
                      <div className="text-center w-full">
                        <Typography.Text className="text-lg">
                          {" "}
                          {item.remainingAmount || "00"}
                        </Typography.Text>
                      </div>
                      <div className="text-right w-full">
                        <Typography.Text className="text-lg">
                          {moment(item?.createdAt).format("h:m a, DD MMM YYYY")}
                        </Typography.Text>
                      </div>
                    </div>
                    <Divider className="my-1" />
                  </div>
                );
              })}
            </div>
            <div className="w-full flex flex-col items-center justify-between text-right">
              <Typography.Text className="flex justify-between text-xl font-bold w-full mt-4">
                <span className="font-bold mr-2">
                  {data?.totalCost || "-- --"}
                </span>
                : کل رقم
              </Typography.Text>
              <Typography.Text className="flex justify-between text-xl font-bold w-full mt-4">
                <span className="font-bold mr-2">
                  {data?.paidAmount || "00"}
                </span>
                : ادا شدہ رقم
              </Typography.Text>
              <Typography.Text className="flex justify-between text-xl font-bold w-full mt-4">
                <span className="font-bold mr-2">{data?.discount || "00"}</span>
                : رعایت
              </Typography.Text>
              <Typography.Text className="flex justify-between text-xl font-bold w-full mt-4">
                <span className="font-bold mr-2">
                  {data?.remainingAmount || "00"}
                </span>
                : بقایا رقم
              </Typography.Text>
            </div>
            <Divider />
            {hideHtml ? null : (
              <Row justify={"end"} gutter={12} className="mt-6">
                <Col>
                  <Button htmlType="submit" size="large">
                    Ok
                  </Button>
                  <Button
                    onClick={handlePrint}
                    size="large"
                    type="primary"
                    className="ml-2"
                  >
                    Print
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default ViewTransactionsModal;
