import React, { useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';

const { Text } = Typography;

const DeleteModal = ({
    isModalOpen,
    setIsModalOpen,
    deleteAction,
    title = "Delete Record",
    text = "Are you sure you want to delete?",
    actionText = "Delete"
}) => {
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Modal title={title} open={isModalOpen} footer={false} onCancel={handleCancel}>
                <div>
                    <div className='py-3'>
                        <Text className='text-lg'>{text}</Text>
                    </div>
                    <Row justify={"end"} gutter={12}>
                        <Col>
                            <Button size="large" onClick={deleteAction} className='bg-red-500 text-white' >
                                {actionText}
                            </Button>
                        </Col>
                        <Col>
                            <Button size="large" onClick={() => setIsModalOpen(false)}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};
export default DeleteModal;