import { createSlice } from "@reduxjs/toolkit";
import { getUserProfile, setUserProfile } from "../actions/index";

export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: {},
    authenticated: false,
    loggedIn: false,
    isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.loggedIn = true;
        state.authenticated = true;
        state.isLoading = false;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(setUserProfile.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(setUserProfile.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.loggedIn = true;
        state.authenticated = true;
        state.isLoading = false;
      })
      .addCase(setUserProfile.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default userSlice;
